export const SUPPORT_TOKENS = 'supportTokens'

export const getSupportToken = (networkId: string): undefined | string => {
  const tokens = getSupportTokens() || []
  return tokens.find(t => t?.networkId === networkId)?.accessToken
}
export const getSupportTokens = ():
  | undefined
  | Array<{
      networkId: string
      accessToken: string
    }> => {
  if (typeof window === 'undefined') {
    return
  }

  const storage = window.localStorage
  const res = storage.getItem(SUPPORT_TOKENS)
  if (typeof res === 'string' && res.length > 0) {
    try {
      return JSON.parse(res)
    } catch (error) {
      storage.removeItem(SUPPORT_TOKENS)
    }
  }
}
export const setSupportNetworkToken = (
  networkId: string,
  token: string,
): void => {
  if (typeof window === 'undefined') {
    return
  }
  const storage = window.localStorage
  let tokens = getSupportTokens() || []
  // remove existing token
  tokens = tokens.filter(t => t.networkId !== networkId)
  // remove extra token to keep the storage size small
  if (tokens.length > 10) {
    tokens.shift()
  }
  tokens.push({
    networkId,
    accessToken: token,
  })

  try {
    storage.setItem(SUPPORT_TOKENS, JSON.stringify(tokens))
  } catch (e) {
    storage.removeItem(SUPPORT_TOKENS)
  }
}
