import { ThemeTokens } from 'containers/AdminSettings/hooks/useThemeSettings'

interface TribeWindow {
  themeSettings: ThemeTokens | null
}
declare global {
  interface Window {
    Tribe: TribeWindow
  }
}

const isClient = typeof window !== 'undefined'

export const initializeTribeWindow = () => {
  if (isClient && !window.Tribe) {
    window.Tribe = {
      themeSettings: null,
    }
    Object.seal(window.Tribe)
  }
}

export const setGlobalThemeSettings = (themeSettings: ThemeTokens) => {
  if (isClient) {
    window.Tribe.themeSettings = themeSettings
  }
}

export const getGlobalThemeSettings = () => {
  if (isClient) {
    return window.Tribe?.themeSettings
  }
}
